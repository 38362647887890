
import { computed, defineComponent, onMounted, ref } from "vue";
import { Prescriber } from "@/store/modules/PrescriberModule";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import _ from "lodash";
import { Modal } from "bootstrap";
import ImageUpload from "@/components/global/UploadImage.vue";
import { getImageUrl } from "@/core/helpers/globalJaya";

export default defineComponent({
  name: "prescriberContent-content",
  methods: { getImageUrl },
  props: {
    mode: { type: String, default: "display" },
    user: { type: String, default: "farmer" },
    prescriberId: { type: Number, required: false },
  },
  components: {
    ImageUpload,
    Field,
    Form,
    ErrorMessage,
  },

  setup: function (props) {
    const store = useStore();
    const router = useRouter();
    const submitButtonPrescriberContent = ref<HTMLButtonElement | null>(null);
    let modal: Modal | null = null;
    const openModal = () => {
      const modalEl = document.getElementById("uploadModal");
      modal = new Modal(modalEl);
      modal.show();
    };
    const setImage = (e) => {
      prescriberContent.value.image_url = e.url;
      prescriberContent.value.image = e.id;
      modal.hide();
    };
    const prescriberContent = ref<Prescriber>({
      id: null,
      name: "",
      admin: "",
      image_url: "",
      image: null,
      communication_website: false,
      communication_external: false,
    });

    const originalPrescriberContent = ref<Prescriber>({
      ...prescriberContent.value,
    });

    const prescriberContentValidator = Yup.object().shape({
      name: Yup.string()
        .required("Le champ ${label} est requis")
        .max(256, "Le champ ${label} ne doit pas dépasser ${max} caractères")
        .label("nom du prescripteur")
        .nullable(),
      admin: Yup.string()
        .min(4)
        .required("L'email de l'administrateur est requis")
        .email("Le format de l'email de l'administrateur n'est pas valide")
        .max(254, "Le ${label} ne doit pas dépasser ${max} caractères")
        .nullable(),
    });

    const prescriberContentChanged = computed(() => {
      return !_.isEqual(
        prescriberContent.value,
        originalPrescriberContent.value
      );
    });

    onMounted(() => {
      if (props.prescriberId) {
        store.dispatch(Actions.FETCH_PRESCRIBER_FOUNDERS).then(() => {
          prescriberContent.value = store.getters.getPrescribers.find(
            (e) => e.id === props.prescriberId
          );
          originalPrescriberContent.value = {
            ...prescriberContent.value,
          };
        });
      }
    });
    const onSubmitPrescriberContent = () => {
      submitButtonPrescriberContent.value?.setAttribute(
        "data-kt-indicator",
        "on"
      );

      let payload = prescriberContent.value;

      if (props.mode === "create") {
        store
          .dispatch(Actions.ADD_PRESCRIBER_FOUNDERS, payload)
          .then(() => router.push({ name: "founder-backoffice" }))
          .catch(() => router.push({ name: "new-prescriber" }));
      } else if (props.mode === "update") {
        store
          .dispatch(Actions.UPDATE_PRESCRIBER_FOUNDERS, payload)
          .then(() => router.push({ name: "founder-backoffice" }));
      }

      submitButtonPrescriberContent.value?.removeAttribute("data-kt-indicator");
    };

    //TODO change for user.prescriber id
    const isAdminVisible = computed(() => {
      const user = store.getters.currentUser;
      return user.is_founder || user.prescriber_id !== null;
    });

    return {
      prescriberContent,
      prescriberContentValidator,
      prescriberContentChanged,
      onSubmitPrescriberContent,
      openModal,
      isAdminVisible,
      setImage,
      submitButtonPrescriberContent,
    };
  },
});
