
import { defineComponent, ref } from "vue";
import ApiService from "@/core/services/ApiService";
import { notify } from "@/core/helpers/globalJaya";

export default defineComponent({
  name: "image-upload",
  emits: ["upload-done"],
  setup(props, { emit }) {
    const progress = ref(0);

    const uploadFile = (event: Event) => {
      const file = (event.target as HTMLInputElement).files?.[0];
      if (file) {
        const formData = new FormData();
        formData.append("file", file);

        const xhr = new XMLHttpRequest();
        xhr.open("POST", process.env.VUE_APP_API_URL + "/uploads/image/", true);
        const headers = ApiService.getApiHeaders();
        xhr.withCredentials = true;
        for (const head in headers) {
          // eslint-disable-next-line no-prototype-builtins
          if (headers.hasOwnProperty(head) && headers[head] !== null) {
            xhr.setRequestHeader(head, headers[head]);
          }
        }
        xhr.upload.onprogress = function (e) {
          if (e.lengthComputable) {
            progress.value = Math.round((e.loaded * 100) / e.total);
          }
        };

        xhr.onload = function (e) {
          if (200 <= xhr.status && xhr.status < 300) {
            const target = e.target as XMLHttpRequest;
            let response = JSON.parse(target.response);
            emit("upload-done", response);
          } else {
            notify({
              text: "Erreur lors du versement de l'image",
              color: "error",
              time: 3000,
            });
          }
        };

        xhr.send(formData);
      }
    };

    return {
      progress,
      uploadFile,
    };
  },
});
