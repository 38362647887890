import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "progress mt-3" }
const _hoisted_2 = ["aria-valuenow"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("input", {
      type: "file",
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.uploadFile && _ctx.uploadFile(...args)))
    }, null, 32),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: "progress-bar",
        role: "progressbar",
        style: _normalizeStyle({ width: _ctx.progress + '%' }),
        "aria-valuenow": _ctx.progress,
        "aria-valuemin": "0",
        "aria-valuemax": "100"
      }, _toDisplayString(_ctx.progress) + "% ", 13, _hoisted_2)
    ])
  ]))
}